import Auslage from './auslage'
import Person from './person'
import md5 from 'md5'
import User from '../User/user'
import Bank from '../User/bank'
import Reisekosten from './Reisekosten/reisekosten'
import { addDoc, collection, deleteDoc, doc, documentId, getDoc, getDocs, getFirestore, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import Telefonpauschale from './Kosten/telefonpauschale'
import Telefonat from './Kosten/telefonat'
import Kosten from './Kosten/kosten'
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import flvwLogo from './PDF/flvw_logo'
import PDF_Generator from './PDF/pdf-abrechnung'
import Beleg from './Kosten/beleg'
import { getAuth } from 'firebase/auth'

class Abrechnung {
  
    constructor(
        id,
        versionId,
        empfaenger,
        abrechnender,
        reisekosten = [],
        portokosten = [],
        telefonkosten = [],
        sonstige_kosten = [],
        status = 'Neu',
        freigaben = [],
        zeitraum,
        type,
        historie = [],
        anmerkungen = '',
        kostenstelle = [],
        kostentraeger = [],
        printed = false,
        kreditorennummer = '',
        tags = []
    ){
        const tmstmp = Date.now()
        this.id = id || md5(tmstmp)
        this.ref = doc(getFirestore(), 'Abrechnungen/'+this.id)
        this.versionId = versionId || md5(tmstmp)
        this.empfaenger = empfaenger || {
            type: '',
            name: ''
        }
        this.setAbrechnender(abrechnender)
        this.status = status || 'Neu'
        this.reisekosten = reisekosten.map(item => {
            return new Reisekosten(
                item.id, 
                item.datum, 
                item.veranstaltung, 
                item.ort, 
                item.route, 
                item.tagegeld, 
                item.uebernachtungen, 
                item.fahrtkosten, 
                item.summe, 
                item.bemerkungen, 
                item.kostenstelle, 
                item.kostentraeger, 
                item.kennzeichen,
                item.mehrtaegig, 
                item.bis_datum
            )
        })
        this.reisekosten.sort((a,b) => a.datum < b.datum ? -1 : 1)
        this.freigaben = freigaben
        this.portokosten = portokosten
        this.telefonkosten = telefonkosten.map(item => {
            if(item.name){
                return new Telefonpauschale(item.id, item.name, item.datum, item.kosten, item.zeitraum)
            }
            else {
                return new Telefonat(item.id, item.empfaenger, item.einheiten, item.datum, item.kosten)
            }
        })
        this.sonstige_kosten = sonstige_kosten
        this.zeitraum = zeitraum || {
            type: '',
            value: ''
        }
        this.type = type || 'Auslagenabrechnung'
        this.historie = historie
        this.anmerkungen = anmerkungen
        
        this.historie.sort((a,b) => a.timestamp - b.timestamp)
        this.kostenstelle = kostenstelle
        this.kostentraeger = kostentraeger

        this.setKreditorennummer(kreditorennummer)

        //Initiales Laden der Belege
        this.belege = []
        this.get_belege(false)

        this.printed = printed

        const currentYear = new Date().getFullYear()
        this.tags = tags || [currentYear]
        if(this.tags.length == 0){
            this.tags.push(currentYear)
        }

        this.errors = []
    }

    get_belege(onSnap = true){
        if(getAuth().currentUser == null){
            return
        }
        const belegeRef = collection(getFirestore(), 'Abrechnungen/'+this.id+'/Belege')
        if(onSnap){
            const queryRef = query(belegeRef, where('url', '!=', 'Test'))
            this.belegeSnap = onSnapshot(queryRef, snap => {
                this.belege = []
                snap.forEach(beleg => {
                    this.belege.push(new Beleg(beleg.id, this.id, beleg.data().filename, beleg.data().name, beleg.data().beschreibung, beleg.data().url, beleg.data().type))
                })
            })
        }
        else {
            getDocs(belegeRef).then(snap => {
                this.belege = []
                snap.forEach(beleg => {
                    this.belege.push(new Beleg(beleg.id, this.id, beleg.data().filename, beleg.data().name, beleg.data().beschreibung, beleg.data().url, beleg.data().type))
                })
            })
        }
    }

    setKreditorennummer(kreditorennummer){
        if(!kreditorennummer){
            if(this.empfaenger.type.includes('Kreis')){
                this.kreditorennummer = this.abrechnender?.kreditorennummer
            }
            else {
                this.kreditorennummer = this.abrechnender?.kreditorennummer_verband ? this.abrechnender?.kreditorennummer_verband : this.abrechnender?.kreditorennummer
            }
        }
        else {
            this.kreditorennummer = kreditorennummer
        }
    }
    
    async setAbrechnender(abrechnender){
        const newAbrechnender = new User(
            abrechnender?.id, 
            '', 
            abrechnender?.vorname, 
            abrechnender?.nachname, 
            abrechnender?.email, 
            abrechnender?.funktionen, 
            abrechnender?.adresse, 
            abrechnender?.bank instanceof Bank ? new Bank(abrechnender.bank.phrase, abrechnender.bank.toFirestore()) : new Bank(abrechnender?.customFields?.phrase, abrechnender?.bank), 
            abrechnender?.bank_last_changed,
            '', 
            {
                phrase: abrechnender?.customFields?.phrase
            },
            abrechnender?.kreditorennummer,
            [],
            '',
            '',
            [],
            abrechnender?.kfz_kennzeichen,
            [],
            false,
            false,
            abrechnender?.kreditorennummer_verband
        )
        this.abrechnender = newAbrechnender

        if(!this.abrechnender.kreditorennummer && getAuth().currentUser != null){
            const userDoc = await getDoc(doc(getFirestore(), 'User/'+this.abrechnender.id))
            if(userDoc.exists()){
                this.abrechnender.kreditorennummer = userDoc.data().kreditorennummer
            }
        }
        this.setKreditorennummer()
        
    }

    abrechnenderToFirestore(){
        return {
            id: this.abrechnender.id,
            vorname: this.abrechnender.vorname,
            nachname: this.abrechnender.nachname,
            funktionen: this.abrechnender.funktionen.map(item => {
                return item.toFirestore()
            }),
            adresse: this.abrechnender.adresse.toFirestore(),
            bank: this.abrechnender.bank.toFirestore(),
            bank_last_changed: this.abrechnender.bank_last_changed,
            kfz_kennzeichen: this.abrechnender.kfz_kennzeichen,
            customFields: this.abrechnender.customFields,
            kreditorennummer: this.abrechnender.kreditorennummer || '',
            kreditorennummer_verband: this.abrechnender.kreditorennummer_verband || ''
        }
    }

    portokostensumme(){
        var summe = 0
        this.portokosten.forEach(item => {
            summe += parseFloat(item.kosten)
        })
        return parseFloat(summe).toFixed(2)
    }

    reisekostensumme(){
        var summe = 0
        this.reisekosten.forEach(item => {
            summe += parseFloat(item.summe)
        })
        return parseFloat(summe).toFixed(2)
    }

    tagegeldersumme(){
        var summe = 0
        this.reisekosten.forEach(item => {
            if(item.tagegeld?.summe){
                summe += parseFloat(item.tagegeld?.summe)
            }
        })
        return parseFloat(summe).toFixed(2)
    }

    fahrtkostensumme(){
        var summe = 0
        this.reisekosten.forEach(item => {
            summe += parseFloat(item.get_fahrtkosten())
        })
        return parseFloat(summe).toFixed(2)
    }


    telefonkostensumme(){
        var summe = 0
        this.telefonkosten.forEach(item => {
            summe += parseFloat(item.kosten)
        })
        return parseFloat(summe).toFixed(2)
    }
    sonstigekostensumme(){
        var summe = 0
        this.sonstige_kosten.forEach(item => {
            summe += parseFloat(item.kosten)
        })
        return parseFloat(summe).toFixed(2)
    }
    gesamtsumme(){
        var summe = 0
        summe += parseFloat(this.sonstigekostensumme())
        summe += parseFloat(this.telefonkostensumme())
        summe += parseFloat(this.reisekostensumme())
        summe += parseFloat(this.portokostensumme())
        return summe.toFixed(2)
    }

    toFirestore(){
        return {
            id: this.id,
            versionId: this.versionId,
            empfaenger: this.empfaenger,
            abrechnender: this.abrechnenderToFirestore(),
            status: this.status?.status ? this.status.status : this.status ? this.status : 'Neu',
            reisekosten: this.reisekosten.map(item => {
                return item.toFirestore()
            }),
            freigaben: this.freigaben,
            portokosten: this.portokosten,
            telefonkosten: this.telefonkosten.map(item => {
                return item instanceof Kosten ? item.toFirestore() : item
            }),
            sonstige_kosten: this.sonstige_kosten,
            zeitraum: this.zeitraum,
            type: this.type,
            historie: this.historie,
            anmerkungen: this.anmerkungen,
            kostenstelle: this.kostenstelle,
            kostentraeger: this.kostentraeger,
            printed: this.printed,
            kreditorennummer: this.kreditorennummer,
            tags: this.tags
        }
    }

    parseTimestamp(tmstmp){
        const date = new Date(tmstmp)
        return date.getDate().toString().padStart(2, '0')+'.'+(date.getMonth()+1).toString().padStart(2, '0')+'.'+date.getFullYear()+' um '+date.getHours().toString().padStart(2, '0')+':'+date.getMinutes().toString().padStart(2, '0')+':'+date.getSeconds().toString().padStart(2, '0')+' Uhr'
    }
    
    parseTimestampDatum(tmstmp){
        const date = new Date(tmstmp)
        return date.getDate().toString().padStart(2, '0')+'.'+(date.getMonth()+1).toString().padStart(2, '0')+'.'+date.getFullYear()
    }

    logBearbeitung(message, user){
        this.historie.push({
            timestamp: Date.now(),
            message: message,
            user: {
                name: user.displayName,
                rollen: user.rollen,
                kreis: user.customFields.kreis || 'FLVW'
            }
        })
        this.historie.sort((a,b) => b.timestamp - a.timestamp)
    }

    addFreigabeLog(message, user, rolle = '', hide_signature = false, icon = ['far', 'check'], color = 'success'){
        this.freigaben.push({
            timestamp: Date.now(),
            message: message,
            icon: icon,
            color: color,
            hide_signature: hide_signature,
            user: {
                name: user.displayName,
                rolle: rolle,
                id: user.id,
                signatur: user.signatur
            }
        })
    }

    translateStatus(){
        var status = {
            status: '',
            color: '',
            icon: '',
            light: false,
            beschreibung: '',
        }

        if(this.status == 'Neu'){
            status = Object.assign(status, {
                status: 'Entwurf',
                color: 'primary',
                icon: ['fal', 'file-lines'],
                beschreibung: 'Die Abrechnung wurde noch nicht versendet und kann noch von dir bearbeitet werden.'
            })
        }
        if(this.status == 'Abgelehnt'){
            status = Object.assign(status, {
                status: 'Abgelehnt',
                color: 'error',
                icon: ['fal', 'file-xmark'],
                beschreibung: 'Die Abrechnung wurde abgelehnt.'
            })
        }
        else if(this.status == 'Eingereicht' && this.empfaenger.type == 'Kreis-Ausschuss'){
            status = Object.assign(status, {
                status: 'Wartet auf Freigabe',
                color: 'secondary',
                light: true,
                icon: ['far', 'hourglass-half'],
                beschreibung: 'Die Abrechnung wartet auf Freigabe durch Vorsitzende*n des '+this.empfaenger.ausschuss.name+'.'
            })
        }
        else if(this.status == 'Offen' && ['Kreis-Ausschuss', 'Kreis'].includes(this.empfaenger.type)){
            status = Object.assign(status, {
                status: 'Wartet auf Genehmigung',
                color: 'secondary',
                light: true,
                icon: ['fal', 'check'],
                beschreibung: 'Die Abrechnung wartet auf Genehmigung durch Kreisvorsitzende*n.'
            })
        }
        else if(this.status == 'Genehmigt' && ['Kreis-Ausschuss', 'Kreis'].includes(this.empfaenger.type)){
            status = Object.assign(status, {
                status: 'In Bearbeitung',
                color: 'secondary',
                light: true,
                icon: ['fal', 'check-double'],
                beschreibung: 'Die Abrechnung wartet auf die Bearbeitung durch Kreiskassierer*in.'
            })
        }
        else if(this.status == 'Offen' && ['Hauptamt', 'Gremium'].includes(this.empfaenger.type)){
            status = Object.assign(status, {
                status: 'Wartet auf Genehmigung',
                color: 'secondary',
                light: true,
                icon: ['fal', 'hourglass-half'],
                beschreibung: 'Die Abrechnung wartet auf Genehmigung durch Abteilungsleitung.'
            })
        }
        else if(this.status == 'Genehmigt' && ['Hauptamt', 'Gremium'].includes(this.empfaenger.type)){
            status = Object.assign(status, {
                status: 'In Bearbeitung',
                color: 'secondary',
                light: true,
                icon: ['fal', 'check'],
                beschreibung: 'Die Abrechnung wartet auf die Bearbeitung durch die Abteilung Finanzen.'
            })
        }
        else if(this.status == 'Abgeschlossen'){
            status = Object.assign(status, {
                status: 'Abgeschlossen',
                color: 'success',
                icon: ['fal', 'file-check'],
                beschreibung: 'Bearbeitung ist abgeschlossen und die Erstattung sollte zeitnah auf deinem angegebenen Konto (Endziffern - '+this.abrechnender.bank.iban.slice(-4) +') eintreffen.'
            })
        }
        return status
    }

    async getNextStatus(){
        if(['Neu', 'Abgelehnt'].includes(this.status) && this.empfaenger.type == 'Kreis-Ausschuss'){
            const kreis = await getDoc(doc(getFirestore(), 'Kreis/'+this.empfaenger.refId))
            const ausschuss = kreis.data().bereiche.find(item => item.id == this.empfaenger.ausschuss?.refId)
            if(ausschuss && ausschuss.eigene_genehmigung == true){
                return 'Eingereicht'
            }
            return 'Offen'
        }
        else if(['Neu', 'Abgelehnt'].includes(this.status)){
            return 'Offen'
        }
        else if(this.status == 'Eingereicht'){
            return 'Offen'
        }
        else if(this.status == 'Offen'){
            return 'Genehmigt'
        }
        else if(this.status == 'Genehmigt'){
            return 'Abgeschlossen'
        }
    }

    async loeschen(user){
        if((this.status == 'Neu' || this.status == 'Abgelehnt') && user.id == this.abrechnender.id){
            return deleteDoc(this.ref).then(() => {
                return true
            }).catch((error) => {
                console.log(error)
                return false
            })
        }
        else {
            return false
        }
    }

    async absenden(user){
        if(['Neu', 'Abgelehnt'].includes(this.status) && user.id == this.abrechnender.id){
            const tmstmp = Date.now()
            var message = 'Eingereicht am '+this.parseTimestamp(tmstmp)
            var icon = ['far', 'check']
            var color = 'success'
            var hide = false
            if(this.status == 'Abgelehnt'){
                message = 'Erneut eingereicht am '+this.parseTimestamp(tmstmp)
                icon = ['far', 'arrow-right-to-arc']
                color = 'info'
                hide = true
            }
            this.setAbrechnender(user)
            this.logBearbeitung(message, user)
            this.addFreigabeLog(message, user, '', hide, icon, color)
            this.status = await this.getNextStatus()
            this.versionId = Date.now()
            await updateDoc(this.ref, this.toFirestore())
            if(this.status == 'Eingereicht'){
                await this.SendeBenachrichtigungen(['ausschuss-notification'])
            }
            else if(['Kreis'].includes(this.empfaenger.type)){
                await this.SendeBenachrichtigungen(['kv-notification'])
            }
            else if(['Gremium', 'Hauptamt'].includes(this.empfaenger.type)){
                await this.SendeBenachrichtigungen(['abteilung-notification'])
            }
            return true
        }
        return false
    }

    async freigeben(user){
        if(this.status != 'Eingereicht' || this.empfaenger.type != 'Kreis-Ausschuss' || !user.ka(this.empfaenger?.ausschuss?.refId)){
            return false
        }
        this.status = await this.getNextStatus()
        const tmstmp = Date.now()
        const message = 'Freigegeben vom '+this.empfaenger.ausschuss.name+' am '+this.parseTimestamp(tmstmp)
        this.logBearbeitung(message, user)
        this.addFreigabeLog(message, user, '')
        this.versionId = Date.now()
        await updateDoc(this.ref, this.toFirestore())
        await this.SendeBenachrichtigungen(['user-notification', 'kv-notification'])
        return true
    }

    async genehmigen(user){
        if(this.status != 'Offen'){
            return false
        }
        const tmstmp = Date.now()
        var messageLog, message

        if(['Kreis', 'Kreis-Ausschuss'].includes(this.empfaenger.type) && user.kv(this.empfaenger.refId)){
            messageLog = 'Genehmigt vom Kreisvorsitzenden am '+this.parseTimestamp(tmstmp)
            message = 'Genehmigt am '+this.parseTimestamp(tmstmp)
            this.logBearbeitung(messageLog, user)
            this.addFreigabeLog(message, user, 'Kreisvorsitzender')
            await this.SendeBenachrichtigungen(['user-notification', 'kk-notification'])
        }
        else if(this.empfaenger.type != 'Kreis' && user.abteilungsleiter()){
            messageLog = 'Genehmigt von Abteilungsleitung am '+this.parseTimestamp(tmstmp)
            message = 'Genehmigt am '+this.parseTimestamp(tmstmp)
            this.logBearbeitung(messageLog, user)
            this.addFreigabeLog(message, user, 'Abteilungsleiter')
            await this.SendeBenachrichtigungen(['user-notification', 'finanzen-notification'])
        }
        else {
            return false
        }

        this.status = await this.getNextStatus()
        await updateDoc(this.ref, this.toFirestore())

        return true
    }

    async ablehnen(user){
        if(
            (this.empfaenger.type == 'Kreis' && user.kreismember(this.empfaenger.refId))
            || (this.empfaenger.type == 'Kreis-Ausschuss' && (user.ka(this.empfaenger?.ausschuss?.refId) || user.kreismember(this.empfaenger.refId)))
            || (this.empfaenger.type != 'Kreis' && (user.abteilungsleiter() || user.finanzen() ))
        ) {
            const tmstmp = Date.now()
            const message = 'Abgelehnt am '+this.parseTimestamp(tmstmp)+'. Begründung: '+this.anmerkungen
            this.logBearbeitung(message, user)
            this.addFreigabeLog(message, user, '', true, ['far', 'xmark'], 'error')
            this.status = 'Abgelehnt'
            await updateDoc(this.ref, this.toFirestore())
            await this.SendeBenachrichtigungen(['user-notification'])
            return true
        }
        return false
    }

    async abschliessen(user){
        const tmstmp = Date.now()
        var message, messageLog

        if(user.kk(this.empfaenger.refId)){
            messageLog = 'Abrechnung abgeschlossen am '+this.parseTimestamp(tmstmp)+' vom Kreiskassierer'
            message = 'Abrechnung abgeschlossen am '+this.parseTimestamp(tmstmp)
            this.logBearbeitung(message, user)
            this.addFreigabeLog(message, user, 'Kreiskassierer')
        }
        else if(user.finanzen()){
            messageLog = 'Abrechnung abgeschlossen am '+this.parseTimestamp(tmstmp)+' von der Abteilung Finanzen'
            message = 'Abrechnung abgeschlossen am '+this.parseTimestamp(tmstmp)
            this.logBearbeitung(message, user)
            this.addFreigabeLog(message, user, 'Finanzen')
        }
        else {
            return false
        }
        this.status = await this.getNextStatus()
        await updateDoc(this.ref, this.toFirestore())
        await this.SendeBenachrichtigungen(['user-notification'])
        return true
    }

    async sendMail(uids = [], template, data = {}) {
        const mailCollection = collection(getFirestore(), 'mail')
        const template_data = Object.assign({
            abrechnung: {
                id: '/'+this.id,
                type: this.type,
                status: this.status,
            },
            abrechnender: {
                name: this.abrechnender.vorname+' '+this.abrechnender.nachname,
            }
        }, data)
        return await addDoc(mailCollection, {
            toUids: uids,
            template: {
                name: template,
                data: template_data
            }
        })
    }

    async sendNotification(template = 'default'){
        const whereArray = []
        const uids = []

        if(template == 'user-notification'){
            uids.push(this.abrechnender.id)
        }
        else if(template == 'kv-notification'){
            whereArray.push(['kreis', '==', this.empfaenger.refId],  ['rollen', 'array-contains', 'Kreisvorsitzender'], ['benachrichtigungen.kv_notification', '==', true])
        }
        else if(template == 'kk-notification'){
            whereArray.push(['kreis', '==', this.empfaenger.refId], ['rollen', 'array-contains', 'Kreiskassierer'], ['benachrichtigungen.kk_notification', '==', true])
        }
        else if(template == 'ausschuss-notification'){
            whereArray.push(['kreis', '==', this.empfaenger.refId], ['benachrichtigungen.ausschuss_notification', '==', true])
        }
        else if(template == 'finanzen-notification'){
            whereArray.push(['rollen', 'array-contains', 'Finanzen'], ['benachrichtigungen.finanzen_notification', '==', true])
        }
        else if(template == 'abteilung-notification'){
            var empfaenger = []
            if(this.empfaenger.type == 'Gremium'){
                const empfRef = doc(getFirestore(), 'Ehrenamt/'+md5(this.empfaenger.name))
                const empfDoc = await getDoc(empfRef)
                if(empfDoc.exists() == true){
                    empfaenger = empfDoc.data().abteilungen
                }
                else {
                    return
                }
            }
            else {
                empfaenger.push(this.empfaenger.name)
            }
            whereArray.push(['abteilung', 'in', empfaenger], ['benachrichtigungen.abteilung_notification', '==', true])
        }

    }

    async SendeBenachrichtigungen(templates = []){
        const mailCollection = collection(getFirestore(), 'mail')
        if(templates.includes('user-notification')){
            const userRef = doc(getFirestore(), 'User/'+this.abrechnender.id)
            const userDoc = await getDoc(userRef)
            if(userDoc.exists){
                const userdata = userDoc.data()
                if(userdata.benachrichtigungen){
                    if(userdata.benachrichtigungen.user_notification){
                        await this.sendMail([userDoc.id], 'user-notification')
                    }
                }
            }
        }
        if(templates.includes('kv-notification') || templates.includes('kk-notification')){
            const KreisNotifyRef = query(collection(getFirestore(), 'User'), where('kreis', '==', this.empfaenger.refId))
            const KreisNotifyDocs = await getDocs(KreisNotifyRef)
            KreisNotifyDocs.forEach(async user => {
                const userdata = user.data()
                if((userdata.rolle == 'Kreisvorsitzender' || userdata.rollen?.includes('Kreisvorsitzender')) && userdata.benachrichtigungen){
                    if(userdata.benachrichtigungen.kv_notification && templates.includes('kv-notification')){
                        await this.sendMail([user.id], 'kv-notification')
                    }
                }
                if((userdata.rolle == 'Kreiskassierer' || userdata.rollen?.includes('Kreiskassierer')) && userdata.benachrichtigungen){
                    if(userdata.benachrichtigungen.kk_notification && templates.includes('kk-notification')){
                        await this.sendMail([user.id], 'kk-notification')
                    }
                }
            })
        }
        if(templates.includes('ausschuss-notification') && this.empfaenger?.refId && this.empfaenger?.ausschuss?.refId){
            const AusschussRef = query(collection(getFirestore(), 'Kreisausschuss'), where('kreisId', '==', this.empfaenger.refId), where('ausschussId', '==', this.empfaenger.ausschuss.refId))
            const AusschussDocs = await getDocs(AusschussRef)
            const AusschussUser = []
            AusschussDocs.forEach(async user => {
                AusschussUser.push(user.data().email)
            })
            const AusschussNotifyRef = query(collection(getFirestore(), 'User'), where('email', 'in', AusschussUser), where('benachrichtigungen.ka_notification', '==', true))
            const AusschussNotifyDocs = await getDocs(AusschussNotifyRef)
            AusschussNotifyDocs.forEach(async user => {
                await this.sendMail([user.id], 'ausschuss-notification')
            })
        }
        if(templates.includes('abteilung-notification')){
            var empfaengernamen = []
            if(this.empfaenger.type == 'Gremium'){
                const empfRef = doc(getFirestore(), 'Ehrenamt/'+md5(this.empfaenger.name))
                const empfDoc = await getDoc(empfRef)
                if(empfDoc.exists() == true){
                    empfaengernamen = empfDoc.data().abteilungen
                }
                else {
                    return
                }
            }
            else {
                empfaengernamen.push(this.empfaenger.name)
            }
            const NotifyRef = query(collection(getFirestore(), 'User'), where('abteilung', 'in', empfaengernamen))
            const NotifyDocs = await getDocs(NotifyRef)
            NotifyDocs.forEach(async user => {
                const userdata = user.data()
                if((userdata.rolle == 'Abteilungsleiter' || userdata.rollen?.includes('Abteilungsleiter')) && userdata.benachrichtigungen){
                    if(userdata.benachrichtigungen.abteilung_notification){
                        await this.sendMail([user.id], 'abteilung-notification')
                    }
                }
            })
        }
        if(templates.includes('finanzen-notification')){
            const FinanzNotifyRef = query(collection(getFirestore(), 'User'), where('rolle', '==', 'Finanzen'))
            const FinanzNotifyDocs = await getDocs(FinanzNotifyRef)
            FinanzNotifyDocs.forEach(async user => {
                const userdata = user.data()
                if(userdata.benachrichtigungen?.finanzen_notification){
                    await this.sendMail([user.id], 'finanzen-notification')
                }
            })
        }
    }

    generatePDF(){
        const pdfGenerator = new PDF_Generator('abrechnung', this);
        return pdfGenerator.generatePDF();
    }

    parseDatum(d){
        if(d){
            const datum = d.split('-')
            return datum[2]+'.'+datum[1]+'.'+datum[0]
        }
        return ''
    }
}

export default Abrechnung